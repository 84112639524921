import React, { useState } from 'react'
import { Alert, Card, Button, FormGroup } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import AuthFormHeader from '../AuthFormHeader'
import { GLOBAL_PATHS } from 'constants/paths'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { InputField } from 'shared/components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Branding from 'shared/branding'

export const LoginForm = ({
  error,
  values,
  handleChange,
  email,
  isSubmitting,
  authenticating,
  submitted,
  isValid,
}) => {
  const {
    authentication: { forgotEmail: forgotEmailEnabled },
  } = useFlags()
  const [redirectToForgotEmail, setRedirectToForgotEmail] = useState(false)
  const [redirectToForgotPassword, setRedirectToForgotPassword] = useState(
    false
  )
  const { phoneNumber } = Branding.current

  if (redirectToForgotEmail)
    return (
      <Redirect
        push={true}
        to={{ pathname: GLOBAL_PATHS.FORGOT_EMAIL, state: { email } }}
      />
    )

  if (redirectToForgotPassword)
    return (
      <Redirect
        push={true}
        to={{ pathname: GLOBAL_PATHS.FORGOT_PASSWORD, state: { email } }}
      />
    )

  return (
    <React.Fragment>
      {error && (
        <Alert color="danger" data-test="error">
          {error}
        </Alert>
      )}
      <Form>
        <FormGroup>
          <InputField
            id="email"
            name="email"
            title="Email"
            value={values.email}
            onChange={handleChange}
            autoComplete="off"
            className="mb-0"
          />

          {forgotEmailEnabled ? (
            <small>
              <span
                className="pseudolink"
                onClick={() => setRedirectToForgotEmail(true)}
              >
                Forgot email?
              </span>
            </small>
          ) : (
            <small>
              If you forgot your email, please call{' '}
              <a tabIndex="-1" href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </a>{' '}
              for assistance.
            </small>
          )}
        </FormGroup>

        <FormGroup className="mb-1">
          <InputField
            id="password"
            name="password"
            title="Password"
            type="password"
            value={values.password}
            onChange={handleChange}
            className="mb-0"
          />
          <small>
            <span
              className="pseudolink"
              onClick={() => setRedirectToForgotPassword(true)}
            >
              Forgot password?
            </span>
          </small>
        </FormGroup>
        <Button
          id="login_form--button-log_in"
          disabled={isSubmitting || !isValid || (authenticating && submitted)}
          type="submit"
          block
          size="lg"
          color="primary"
          data-test="submit"
          className={authenticating && submitted ? 'd-none' : ''}
        >
          Log In
        </Button>
        <Button
          id="login_form--button-logging_in"
          disabled
          block
          color="primary"
          size="lg"
          data-test="submit-logging-in"
          className={authenticating && submitted ? '' : 'd-none'}
        >
          <i data-test="spinner" className="fas fa-spinner fa-spin" /> Logging
          In...
        </Button>
      </Form>
    </React.Fragment>
  )
}

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const LoginFormWithFormik = ({
  error,
  handleSubmit,
  email,
  password,
  authenticating,
  submitted,
}) => {
  return (
    <Card body className="mb-3 mt-5 auth-block">
      <AuthFormHeader headerMessage="Please enter your email address and password to log in." />
      <Formik
        initialValues={{ email, password }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          handleSubmit({ values })
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, values, handleChange, isValid }) => (
          <LoginForm
            error={error}
            values={values}
            isValid={isValid}
            handleChange={handleChange}
            email={email}
            isSubmitting={isSubmitting}
            authenticating={authenticating}
            submitted={submitted}
          />
        )}
      </Formik>
    </Card>
  )
}

export default LoginFormWithFormik
