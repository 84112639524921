import _ from 'lodash'
import { parseLocationSearch } from 'shared/utils'
import {
  ONLINE_STORE_BRAND_NAME,
  COGNITO_SOURCE,
  BRANDS,
} from 'constants/values'
import acaLogo from 'shared/images/aca_logo.svg'
import cngLogo from 'shared/images/logo_2018.svg'
import p360Logo from 'shared/images/P360_Logo.svg'
import pocket360Logo from 'shared/images/Pocket360_Logo.svg'
import xactLogo from 'shared/images/Xact_Logo_Registered.svg'
import poweredByLogo from 'shared/images/PoweredByP360_logo.svg'
import plaid_cng_example from 'shared/images/plaid_cng_example.png'
import plaid_xact_example from 'shared/images/plaid_xact_example.png'
import plaid_aca_example from 'shared/images/plaid_aca_example.png'

const {
  REACT_APP_VERSION,
  REACT_APP_ALLIED_LOGIN,
  REACT_APP_CNG_LOGIN,
  REACT_APP_CCB_LOGIN,
  REACT_APP_XACT_LOGIN,
  REACT_APP_ALLIED_PLAID_REDIRECT,
  REACT_APP_CNG_PLAID_REDIRECT,
  REACT_APP_CCB_PLAID_REDIRECT,
  REACT_APP_XACT_PLAID_REDIRECT,
  REACT_APP_P360_LOGIN,
} = process.env

const branding = [
  {
    name: 'Check `n Go',
    friendlyName: 'Check `n Go',
    key: ONLINE_STORE_BRAND_NAME.CNG,
    originPattern: /account.*checkngo\.com/,
    cssUri: `/index-${REACT_APP_VERSION}.css`,
    logoImage: cngLogo,
    loginUrl: `${REACT_APP_CNG_LOGIN}`,
    loginLogo: cngLogo,
    favicon: 'favicon.cng.ico',
    adverseAction:
      'We are unable to approve you for a loan at this time. You will receive written communication with reasons within the next seven (7) days.',
    adverseActionInStore:
      'We also offer in-store loan products in your state. If you would like to apply, please visit the store shown below or select another store. We hope to see you soon!',
    cognitoSource: COGNITO_SOURCE.CNG,
    availableSources: [
      COGNITO_SOURCE.CNG,
      COGNITO_SOURCE.ANGULAR,
      COGNITO_SOURCE.LEGACY,
    ],
    partnerSiteName: 'Allied Cash Advance',
    partnerSiteUrl: `${REACT_APP_ALLIED_LOGIN}`,
    partnerSiteLogo: acaLogo,
    baseDomain: 'checkngo.com',
    approvedTransitionNeeded: false,
    cookie: {
      name: 'CNG-PPC',
      domain: '.checkngo.com',
      qsKey: 'afscampaignid',
    },
    getAnswersLink: 'https://www.checkngo.com/faqs',
    locationsUrl: 'https://locations.checkngo.com/search.html?q=',
    findAStore: 'https://www.checkngo.com/locations',
    phoneNumber: '1-800-561-2274',
    contactHours:
      'Monday – Friday 8am – 8:30pm (EST) or Saturday 10am – 2pm (EST)',
    productsRequireEligibility: false,
    documentsToApply:
      'a valid ID, proof of income, an active checking account and a working phone number',
    headerColor: 'secondary',
    thankYouDetails: {
      subtitle:
        'If your loan was finalized during business hours, your funds could be deposited as soon as the next business day. Here are the details for your new loan, and you’ll also receive an email shortly. Thanks for choosing Check `n Go.',
      refinanceSubtitle:
        'Congratulations! Your previous loan is now paid. If your loan was finalized during business hours, your funds could be deposited as soon as the next business day. Here are the details for your new loan, and you’ll also receive an email shortly. Thanks for choosing Check `n Go.',
      pdlRefinanceSubtitle:
        'Congratulations! Your previous loan will be paid off. Here are the details for your new loan, and you’ll also receive an email shortly. Thanks for choosing Check `n Go.',
    },
    plaidThemeName: 'cng',
    plaidExampleImage: plaid_cng_example,
    plaidRedirectLink: `${REACT_APP_CNG_PLAID_REDIRECT}`,
    payByCheckEmail: 'loanprocessing@checkngo.com',
    payByCheckPhone: '1-800-286-2601',
  },
  {
    name: 'Allied Cash Advance',
    friendlyName: 'Allied Cash Advance',
    key: ONLINE_STORE_BRAND_NAME.ACA,
    originPattern: /alliedcash\.com/,
    cssUri: `/allied-${REACT_APP_VERSION}.css`,
    logoImage: acaLogo,
    loginUrl: `${REACT_APP_ALLIED_LOGIN}`,
    loginLogo: acaLogo,
    favicon: 'favicon.allied.ico',
    adverseAction:
      "We were unable to match you with a lending partner. More details about why your loan application wasn't approved will be sent to the email address you provided.",
    adverseActionInStore:
      'We also offer in-store loan products in your state. If you would like to apply, please visit the store shown below or select another store. We hope to see you soon!',
    cognitoSource: COGNITO_SOURCE.ACA,
    availableSources: [COGNITO_SOURCE.ACA, COGNITO_SOURCE.CNG],
    partnerSiteName: 'Check `n Go',
    partnerSiteUrl: `${REACT_APP_CNG_LOGIN}`,
    getAnswersLink: 'https://www.alliedcash.com/faqs',
    partnerSiteLogo: cngLogo,
    partnerSiteKey: ONLINE_STORE_BRAND_NAME.CNG,
    baseDomain: 'www.alliedcash.com',
    approvedTransitionNeeded: true,
    cookie: {
      name: 'ACA-PPC',
      domain: '.alliedcash.com',
      qsKey: 'afscampaignid',
    },
    locationsUrl: 'https://locations.alliedcash.com/search.html?q=',
    findAStore: 'https://www.alliedcash.com/locations',
    phoneNumber: '1-800-561-2274',
    contactHours:
      'Monday – Friday 8am – 8:30pm (EST) or Saturday 10am – 2pm (EST)',
    productsRequireEligibility: false,
    documentsToApply:
      'a valid ID, proof of income, an active checking account, a working phone number, and your vehicle registration or proof of ownership',
    headerColor: 'secondary',
    plaidThemeName: 'aca',
    plaidExampleImage: plaid_aca_example,
    plaidRedirectLink: `${REACT_APP_ALLIED_PLAID_REDIRECT}`,
    payByCheckEmail: 'loanprocessing@checkngo.com',
    payByCheckPhone: '1-800-286-2601',
  },
  {
    name: 'Check `n Go, servicer for CCBank',
    friendlyName: 'Xact® Loan',
    key: ONLINE_STORE_BRAND_NAME.CCB,
    originPattern: /ccbank.*checkngo\.com/,
    cssUri: `/xact-${REACT_APP_VERSION}.css`,
    logoImage: xactLogo,
    loginUrl: `${REACT_APP_CCB_LOGIN}`,
    loginLogo: cngLogo,
    favicon: 'favicon.xact.ico',
    adverseAction: `We are unable to approve you for the loan you applied for. Within the next thirty (30) days you'll receive a written message that outlines the reasons for this decision.`,
    cognitoSource: COGNITO_SOURCE.CCB,
    availableSources: [
      COGNITO_SOURCE.CCB,
      COGNITO_SOURCE.ANGULAR,
      COGNITO_SOURCE.LEGACY,
    ],
    partnerSiteUrl: `${REACT_APP_CNG_LOGIN}`,
    cookie: {
      name: 'CNG-PPC',
      domain: '.checkngo.com',
      qsKey: 'afscampaignid',
    },
    locationsUrl: 'https://locations.checkngo.com/search.html?q=',
    phoneNumber: '1-800-316-4220',
    contactHours: 'Monday – Friday 8am – 5pm (EST)',
    howItWorksLink: 'https://xact.com/how-it-works',
    getAnswersLink: 'https://xact.com/get-answers',
    productsRequireEligibility: true,
    partnerBrand: ONLINE_STORE_BRAND_NAME.CNG,
    baseDomain: 'checkngo.com/xact',
    productDisclosure: '* Loans provided by CCBank, MEMBER FDIC',
    headerColor: 'secondary',
    thankYouDetails: {
      subtitle:
        'Here are the details for your new Xact installment loan from CCBank. You’ll receive an email with more details shortly. Thanks for choosing Xact.',
    },
    plaidThemeName: 'xact',
    plaidExampleImage: plaid_xact_example,
    plaidRedirectLink: `${REACT_APP_CCB_PLAID_REDIRECT}`,
    payByCheckEmail: 'loanprocessing@xact.com',
    payByCheckPhone: '1-800-316-4220',
  },
  {
    name: 'Xact®',
    friendlyName: 'Xact® Loan',
    key: ONLINE_STORE_BRAND_NAME.CCB,
    originPattern: /apply.*xact\.com/,
    cssUri: `/xact-${REACT_APP_VERSION}.css`,
    logoImage: xactLogo,
    loginUrl: `${REACT_APP_XACT_LOGIN}`,
    loginLogo: pocket360Logo,
    favicon: 'favicon.xact.ico',
    adverseAction: `We are unable to approve you for the loan you applied for. Within the next thirty (30) days you'll receive a written message that outlines the reasons for this decision.`,
    adverseActionEsign:
      'We are unable to approve you for a loan at this time. You will receive written communication with reasons within the next thirty (30) days.',
    cognitoSource: COGNITO_SOURCE.CCB,
    availableSources: [
      COGNITO_SOURCE.CCB,
      COGNITO_SOURCE.ANGULAR,
      COGNITO_SOURCE.LEGACY,
    ],
    partnerSiteUrl: `${REACT_APP_CNG_LOGIN}`,
    cookie: {
      name: 'XACT-PPC',
      domain: '.xact.com',
      qsKey: 'afscampaignid',
    },
    locationsUrl: 'https://locations.checkngo.com/search.html?q=', // Need to ask?
    phoneNumber: '1-800-316-4220',
    contactHours: 'Monday – Friday 8am – 5pm (EST)',
    getAnswersLink: 'https://xact.com/get-answers',
    howItWorksLink: 'https://xact.com/how-it-works',
    productsRequireEligibility: true,
    partnerBrand: ONLINE_STORE_BRAND_NAME.CNG,
    baseDomain: 'xact.com',
    productDisclosure: '* Loans provided by CCBank, MEMBER FDIC',
    headerColor: 'secondary',
    thankYouDetails: {
      subtitle:
        'Here are the details for your new Xact installment loan from CCBank. You’ll receive an email with more details shortly. Thanks for choosing Xact.',
      refinanceSubtitle:
        'Congratulations! Your previous loan is now paid. If your loan was finalized during business hours, your funds could be deposited as soon as the next business day. Here are the details for your new Xact installment loan from CCBank. You’ll also receive an email shortly. Thanks for choosing Xact.',
    },
    plaidThemeName: 'xact',
    plaidExampleImage: plaid_xact_example,
    plaidRedirectLink: `${REACT_APP_XACT_PLAID_REDIRECT}`,
    payByCheckEmail: 'loanprocessing@xact.com',
    payByCheckPhone: '1-800-316-4220',
    checkAuthorizationForm: 'https://www.xact.com/docs/xact/legal-documents/xact-paper-check-application.pdf'
  },
  {
    name: 'pocket360',
    key: ONLINE_STORE_BRAND_NAME.P360,
    originPattern: /.*pocket360\.com/,
    cssUri: `/p360-${REACT_APP_VERSION}.css`,
    logoImage: pocket360Logo,
    loginUrl: `${REACT_APP_P360_LOGIN}`,
    loginLogo: pocket360Logo,
    favicon: 'favicon.p360.ico',
    cognitoSource: COGNITO_SOURCE.CNG,
    availableSources: [
      COGNITO_SOURCE.CNG,
      COGNITO_SOURCE.ANGULAR,
      COGNITO_SOURCE.LEGACY,
    ],
    partnerSiteUrl: `${REACT_APP_CNG_LOGIN}`,
    cookie: {
      name: 'P360-PPC',
      domain: '.pocket360.com',
      qsKey: 'afscampaignid',
    },
    productsRequireEligibility: true,
    partnerBrand: ONLINE_STORE_BRAND_NAME.CNG,
    baseDomain: 'pocket360.com/',
    headerColor: 'secondary',
  },
]

const getBrandingFromDocument = ({ location: { origin } }) => origin
const getBrandingFromLocation = ({ location }) => {
  if (location && location.search) {
    const { brand } = parseLocationSearch(location.search.toLowerCase())
    return brand
  }
  return undefined
}
const searchBranding = pattern =>
  pattern
    ? branding
      .filter(brand => pattern.match(brand.originPattern))
      .find(brand => brand)
    : undefined

const currentBranding = document => {
  return (
    searchBranding(getBrandingFromDocument(document)) ||
    searchBranding(getBrandingFromLocation(document)) ||
    branding[0]
  )
}

let ldOverrides = undefined

const mapFullBrandingDetailsForPage = (currentBrand, pageOverrides) => {
  if (pageOverrides) {
    return {
      ..._.omit(currentBrand, ['cssUri', 'logoImage']),
      cssUri: pageOverrides.cssUri,
      logoImage: pageOverrides.logoImage,
      loginLogo: pageOverrides.loginLogo || currentBrand.loginLogo,
      imagePath: pageOverrides.imagePath || '/',
      favicon: pageOverrides.favicon || currentBrand.favicon,
      name: pageOverrides.name || currentBrand.name,
      originName: currentBrand.name,
      headerColor: pageOverrides.headerColor || currentBrand.headerColor,
      phoneNumber: pageOverrides.phoneNumber || currentBrand.phoneNumber,
    }
  } else {
    return currentBrand
  }
}

class Branding {
  currentBranding = currentBranding(document)

  addCssLink () {
    const defaultPageOverrides = this.setPageOverrides()

    const cssLink = document.createElement('link')
    cssLink.setAttribute('rel', 'stylesheet')
    cssLink.setAttribute('type', 'text/css')
    cssLink.onload = () => {
      document.getElementById('root').style.display = 'block'
    }

    cssLink.setAttribute('href', defaultPageOverrides.cssUri)
    document.head.appendChild(cssLink)

    const faviconLink = document.createElement('link')
    faviconLink.setAttribute('type', 'image/x-icon')
    faviconLink.setAttribute('rel', 'shorcut icon')

    faviconLink.setAttribute('href', `${this.currentBranding.favicon}`)
    faviconLink.setAttribute(
      'href',
      `${defaultPageOverrides.favicon || this.currentBranding.favicon}`
    )

    document.head.appendChild(faviconLink)

    setTimeout(() => {
      const root = document.getElementById('root')
      if (root.style.display === 'none') {
        root.style.display = 'block'
      }
    }, 600)
  }

  getLogos (style, loginLogo) {
    return {
      logoImage: this.getLogo(style.logoImage),
      loginLogo: style.loginLogo ? this.getLogo(style.loginLogo) : loginLogo,
    }
  }

  checkLdOverrides (path, loginLogo, phoneNumber) {
    let style
    for (style of ldOverrides.overrides) {
      if (path.startsWith(style.path)) {
        return _.pickBy({
          cssUri: style.cssUri.replace(
            '__REACT_APP_VERSION__',
            REACT_APP_VERSION
          ),
          imagePath: style.path,
          favicon: style.favicon,
          name: style.name,
          phoneNumber: style.phoneNumber || phoneNumber,
          headerColor: style.headerColor,
          ...this.getLogos(style, loginLogo),
        })
      }
    }
  }

  setPageOverrides () {
    const { cssUri, logoImage, phoneNumber, loginLogo } = this.currentBranding
    const path = _.get(document, 'location.pathname')

    if (path && ldOverrides && ldOverrides.overrides) {
      const overrideCheck = this.checkLdOverrides(path, loginLogo, phoneNumber)
      if (overrideCheck) {
        return overrideCheck
      }
    }

    return { cssUri, logoImage, imagePath: '/' }
  }

  getLogo (logoPath) {
    switch (logoPath) {
      case BRANDS.ACA:
        return acaLogo
      case BRANDS.P360:
        return p360Logo
      case BRANDS.POCKET360:
        return pocket360Logo
      case BRANDS.POWERED_BY:
        return poweredByLogo
      default:
        return cngLogo
    }
  }

  getPageBrandingDetails = currentBrand => {
    const pageOverrides = this.setPageOverrides()
    return mapFullBrandingDetailsForPage(currentBrand, pageOverrides)
  }

  get current () {
    const currentBrand = this.currentBranding
    return this.getPageBrandingDetails(currentBrand)
  }

  setLaunchDarklyOverrides (overrides) {
    ldOverrides = overrides
  }

  getBrand = brandKey => {
    if (brandKey) {
      const currentBrands = branding.filter(b => b.key === brandKey)
      const currentBrand = this.getCurrentBrand(currentBrands, brandKey)
      if (currentBrand) return this.getPageBrandingDetails(currentBrand)
    }

    return undefined
  }

  getCurrentBrand = (currentBrands, brandKey) => {
    if (brandKey === ONLINE_STORE_BRAND_NAME.CCB && currentBrands) {
      return currentBrands.find(
        brand => brand.loginUrl === REACT_APP_XACT_LOGIN
      )
    } else if (currentBrands) {
      return _.head(currentBrands)
    }

    return undefined
  }
}

export default new Branding()
