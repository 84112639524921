import React from 'react'
import PropTypes from 'prop-types'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { Card, CardBody, Form, Button, Alert, FormGroup } from 'reactstrap'
import AuthFormHeader from '../AuthFormHeader'
import { InputField, LDComponent, MfaIdleTimer } from 'shared/components'
import { APPLICATION_PATHS, ENROLLMENT_PATHS } from 'constants/paths'
import { Redirect } from 'react-router-dom'

const headerMessage =
  'Enter the email address associated with your account and we will send you instructions for resetting your password.'
const oldHeaderMessage = 'Enter the email address associated with your account.'

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
})

class ForgotPasswordFormikForm extends React.Component {
  constructor(props) {
    super(props)
    const { email, setFieldValue } = props
    if (email) {
      setFieldValue('email', email)
    }

    this.state = {
      submitted: false,
      redirectToEnroll: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { email, setFieldValue } = this.props
    const { email: newEmail } = nextProps

    if (email !== newEmail) {
      setFieldValue('email', newEmail)
    }
  }

  doSubmit = e => {
    const { formikSubmit } = this.props
    this.setState({ submitted: true })
    formikSubmit(e)
  }

  render() {
    const {
      isValid,
      errors,
      touched,
      processing,
      resetComplete,
      resetSuccess,
      showRedirectToEnroll,
      passwordless,
      search
    } = this.props
    const { submitted, redirectToEnroll } = this.state

    if (redirectToEnroll)
      return <Redirect push={true} to={ENROLLMENT_PATHS.ENROLLMENT} />

    if (passwordless) {
      return <Redirect
        to={{
          pathname: APPLICATION_PATHS.RESUME_PASSWORDLESS,
          state: {emailAddress:search}
        }}
      />
    }

    return (
      <LDComponent>
        {({ flags: { authentication: authenticationFlags } }) => {
          const { forgotPassword: forgotPasswordEnabled } =
            authenticationFlags || {}

          return (
            <Card className="auth-block mb-3 mt-5">
              <MfaIdleTimer />
              <CardBody>
                <AuthFormHeader
                  headerMessage={
                    forgotPasswordEnabled ? headerMessage : oldHeaderMessage
                  }
                />
                {resetComplete && resetSuccess && (
                  <Alert color="primary" className="mt-1">
                    {forgotPasswordEnabled
                      ? 'Please check your email and click on the provided link to reset your password.'
                      : 'Instructions for resetting your password have been sent to your email'}
                  </Alert>
                )}
                {resetComplete &&
                  (resetSuccess || (
                    <Alert color="danger" className="mt-1">
                      {forgotPasswordEnabled ? (
                        <React.Fragment>
                          <p>
                            We couldn’t find an account matching the email
                            address you entered. Please review and try again.
                          </p>
                        {showRedirectToEnroll && (
                          <p>
                            <span
                              className="red-alert-link"
                              onClick={() =>
                                this.setState({ redirectToEnroll: true })
                              }
                            >
                              Don’t have an account? Enroll now.
                            </span>
                          </p>)}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          Either your account information entered is incorrect
                          or you no longer have saved data. We only keep
                          incomplete applications for 14 days.
                        </React.Fragment>
                      )}
                    </Alert>
                  ))}
                <Form onSubmit={this.doSubmit}>
                  <FormGroup>
                    <InputField
                      name="email"
                      title="Email"
                      id="email"
                      invalid={!!(submitted && touched.email && errors.email)}
                      autoComplete="off"
                      disabled={processing}
                    />
                  </FormGroup>

                  <Button
                    id="forgot_password--button-processing"
                    className={processing ? '' : 'd-none'}
                    block
                    type="submit"
                    color="primary"
                    size="lg"
                    disabled
                  >
                    <i className="fas fa-spinner fa-spin" />
                    &nbsp;Processing...
                  </Button>

                  <Button
                    id="forgot_password--button-send_password"
                    className={processing ? 'd-none' : ''}
                    block
                    type="submit"
                    color="primary"
                    size="lg"
                    disabled={!isValid}
                  >
                    Send Password
                  </Button>
                </Form>
              </CardBody>
            </Card>
          )
        }}
      </LDComponent>
    )
  }

  static propTypes = {
    email: PropTypes.string,
    setFieldValue: PropTypes.func.isRequired,
    formikSubmit: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    processing: PropTypes.bool.isRequired,
    resetComplete: PropTypes.bool.isRequired,
    resetSuccess: PropTypes.bool.isRequired,
    showRedirectToEnroll: PropTypes.bool.isRequired
  }
}

const ForgotPasswordForm = ({
  email,
  handleSubmit,
  processing,
  resetComplete,
  resetSuccess,
  showRedirectToEnroll,
  passwordless,
  search,
}) => (
  <Formik
    initialValues={{ email: '' }}
    validationSchema={schema}
    onSubmit={handleSubmit}
    render={({
      handleSubmit: formikSubmit,
      isValid,
      setFieldValue,
      errors,
      touched,
    }) => (
      <ForgotPasswordFormikForm
        {...{
          email,
          formikSubmit,
          isValid,
          setFieldValue,
          errors,
          touched,
          processing,
          resetComplete,
          resetSuccess,
          showRedirectToEnroll,
          passwordless,
          search
        }}
      />
    )}
  />
)

ForgotPasswordForm.propTypes = {
  email: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  resetComplete: PropTypes.bool.isRequired,
  resetSuccess: PropTypes.bool.isRequired,
  showRedirectToEnroll: PropTypes.bool.isRequired,
}

export default ForgotPasswordForm
