import qs from 'querystring'
import moment from 'moment'
import { getConformedMask, phoneMask, ssnMask } from './Masks'
import get from 'lodash/get'

const parseLocationSearch = search => {
  let trimSearch = search
  if (trimSearch.startsWith('?')) {
    trimSearch = trimSearch.substring(1)
  }

  return qs.parse(trimSearch) || {}
}

const parseLocationHash = hash => {
  let trimSearch = hash
  if (hash) {
    trimSearch = hash.substring(1)
  }

  return qs.parse(trimSearch) || {}
}

const ssn = val => getConformedMask(val, ssnMask)

const phone = val => getConformedMask(val, phoneMask)

const cleanSsn = val => (val ? val.replace(/[ ()-/_]/g, '') : val)

const cleanPhone = val => (val ? val.replace(/[ ()-/_]/g, '') : val)

const getEmail = (emails, type) => {
  if (emails) {
    const result = emails.find(e => e.type === type)
    if (result) {
      return result.email
    }
    return null
  }
  return null
}

const getPhone = (phones, phoneType) => {
  if (phones) {
    return phones.find(x => x.type === phoneType)
  }
  return null
}

// gets QueryString params from a Redirect with state
const getQSParams = props => {
  return get(props, 'location.state', false)
}

const getPersonalPhones = (phones, phoneType) => {
  if (!phones) {
    return null
  }
  return phones.find(phone => phone.type === phoneType)
}

const getPersonalPhoneType = phones => {
  if (!phones) {
    return null
  }
  const primaryPhone = phones.find(phone => phone.phonePreference === 'PRIMARY')
  return get(primaryPhone, 'type')
}

const getPhoneNumber = (phones, phoneType) => {
  const phone = getPhone(phones, phoneType)
  if (phone) {
    return phone.number
  } else {
    return ''
  }
}

const constructAddress = address => {
  return (
    address || {
      addressLine1: '',
      addressLine2: '',
      cityName: '',
      zipCode: '',
      stateCode: '',
    }
  )
}

const is = x => x !== undefined && x !== null
const isArray = Array.isArray
const isObject = x => typeof x === 'object' && is(x)

const merge = (dest, src) => {
  if (isArray(dest) && isArray(src)) {
    return mergeArray(dest, src)
  }

  if (isObject(dest) && isObject(src)) {
    return mergeObject(dest, src)
  }

  if (src === undefined) {
    return dest
  }

  return src
}

const mergeArray = (dest, src) => {
  const merged = dest.map((destValue, i) => merge(destValue, src[i]))

  return dest.length < src.length
    ? merged.concat(src.slice(merged.length))
    : merged
}

const mergeObject = (dest, src) => {
  return Object.keys(src).reduce((acc, key) => {
    acc[key] = merge(dest[key], src[key])

    return acc
  }, dest)
}

const compose = (...fns) => (...args) =>
  fns.reduceRight((acc, fn) => [fn.call(null, ...acc)], args)[0]

const processValidationError = (err, fieldName, setFieldTouched, errors) => {
  const invalid = err.inner.find(p => p.path === fieldName)
  if (invalid) {
    if (fieldName === 'timeAtResidence') {
      setFieldTouched('timeAtResidence.years')
      setFieldTouched('timeAtResidence.months')
    } else {
      setFieldTouched(fieldName)
    }
    errors[fieldName] = invalid.message
  } else {
    delete errors[fieldName]
  }
}

const currency = (amount, decimals = 0) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
  })
  return currencyFormatter.format(amount)
}

const calculateIncrements = (min, max, increment) =>
  Array.from(
    { length: (max - min) / increment + 1 },
    (_, i) => min + i * increment
  )

const calculateMidpoint = (min, max, increment) => {
  const increments = calculateIncrements(min, max, increment)
  return increments[Math.floor(increments.length / 2)]
}

const validateCurrency = (parent, value, allowNegative = false) => {
  let currencyRegex = /^[0-9]*$/
  if (allowNegative) {
    currencyRegex = /^-?[0-9]*$/
  }
  return currencyRegex.test(value)
}

const minBirthdate = moment().subtract(18, 'year')

const phoneRegex = /^$|[0-9]{10}|[(]{1}\d{3}[)]{1}[ ]{1}-?\d{3}-?\d{4}/

const ssnRegex = /^(\d{9})$/

const poBoxRegex = /(?:P(?:ost(?:al)?)?[.\-\s]*(?:(?:O(?:ffice)?[.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d)/i

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

const specialCharRegex = /[^\w\s]/gi

const postDynamicForm = (url, dataObject, target) => {
  var form = document.createElement('form')
  form.action = url
  form.method = 'POST'
  form.target = target || '_self'
  if (dataObject) {
    for (var key in dataObject) {
      form.appendChild(generateFormInput(dataObject, key))
    }
  }
  form.style.display = 'none'
  form.enctype = 'application/html'
  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}

const generateFormInput = (dataObject, key) => {
  let input = document.createElement('textarea')
  input.name = key
  input.value =
    typeof dataObject[key] === 'object'
      ? JSON.stringify(dataObject[key])
      : dataObject[key]
  return input
}

// if not installed, it returns undefined. 
const getGPCStatus = () => navigator.globalPrivacyControl ? true : false

export {
  currency,
  calculateIncrements,
  calculateMidpoint,
  // floatToCurrency, //not used
  parseLocationSearch,
  parseLocationHash,
  ssn,
  cleanSsn,
  cleanPhone,
  getEmail,
  phone,
  getGPCStatus,
  getPhone,
  getPersonalPhones,
  getPersonalPhoneType,
  getPhoneNumber,
  getQSParams,
  constructAddress,
  is,
  isArray,
  isObject,
  merge,
  compose,
  processValidationError,
  validateCurrency,
  minBirthdate,
  phoneRegex,
  ssnRegex,
  poBoxRegex,
  specialCharRegex,
  postDynamicForm,
  emailRegex
}
