import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import Feature from 'features'
import {
  AuthenticatedRoute,
  LoginRoute,
  LoginRouteSSO,
} from 'authentication/components'
import { asyncComponent, Helmet } from 'shared/components'
import CampaignIdMonitor from 'application/components/Campaign'
import Branding from 'shared/branding'
import { config } from 'shared/sentry'
import { LoanConversion } from 'loan/components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  GLOBAL_PATHS,
  APPLICATION_PATHS,
  ACCOUNT_PATHS,
  ENROLLMENT_PATHS,
  ADMIN_PATHS,
  LOAN_PATHS,
} from 'constants/paths'
import QueryStringMonitor from 'shared/components/QueryStringMonitor'
import { ONLINE_STORE_BRAND_NAME } from 'constants/values'
import PartnerSiteRedirectWrapper from 'shared/components/PartnerSiteRedirect/PartnerSiteRedirectWrapper'
import isEmpty from 'lodash/isEmpty'
import { parseLocationSearch, parseLocationHash } from 'shared/utils'

const AsyncErrorPage = asyncComponent(() =>
  import('./shared/components/ErrorPage')
)

const AsyncMyAccountErrorPage = asyncComponent(() =>
  import('./account/components/ErrorPage')
)

const AsyncPendingCoreUser = asyncComponent(() =>
  import('./shared/components/PendingCoreUser')
)

const AsyncAdverseActionPage = asyncComponent(() =>
  import('./shared/components/AdverseAction')
)

const AsyncAdverseActionThankYouPage = asyncComponent(() =>
  import('./shared/components/AdverseAction/AdverseActionThankYouPage')
)

const AsyncAdverseActionStorePage = asyncComponent(() =>
  import('./shared/components/AdverseAction/AdverseActionStoreLocatorPage')
)

const AsyncAdverseActionKeepUsInMindPage = asyncComponent(() =>
  import('./shared/components/AdverseAction/KeepUsInMindPage')
)

const AsyncForgotPassword = asyncComponent(() =>
  import('./authentication/components/ForgotPassword')
)

const AsyncForgotEmail = asyncComponent(() =>
  import('./authentication/components/ForgotEmail')
)

const AsyncChangePassword = asyncComponent(() =>
  import('./authentication/components/ChangePassword')
)
const AsyncApplicationWrapper = asyncComponent(() =>
  import('./application/components/ApplicationWrapper')
)

const AsyncAdminLandingPage = asyncComponent(() =>
  import('./admin/components/AdminLandingPage')
)

const AsyncRetailMyAccount = asyncComponent(() =>
  import('./account/components/MyAccount/RetailMyAccountWrapper')
)

const AsyncEnrollmentPage = asyncComponent(() =>
  import('./enrollment/components')
)

const AsyncMfaWrapper = asyncComponent(() =>
  import('./authentication/components/MfaWrapper/MfaWrapper')
)

const AsyncVerificationFailed = asyncComponent(() =>
  import('./authentication/components/VerificationFailed')
)

const AsyncResumePasswordless = asyncComponent(() =>
  import('./application/components/Resume')
)

const { name } = Branding.current

config()

const App = () => {
  const {
    authentication: {
      forgotPassword: forgotPasswordEnabled,
      forgotEmail: forgotEmailEnabled,
    },
    maintenenceMode,
    reactMyAccount,
    redirectToP360,
    enablePasswordlessFlow,
  } = useFlags()

  const isPocket360Domain = window.location.hostname.indexOf('pocket360') > -1

  if (maintenenceMode) {
    window.location.href = process.env.REACT_APP_MAINTENCE_URL
  }

  return (
    <div className="mainBody">
      <Helmet brandName={name} pageName="" />
      <CampaignIdMonitor />
      <QueryStringMonitor />
      <Switch>
        <Route path={GLOBAL_PATHS.ERROR} component={AsyncErrorPage} />
        <Route path={ACCOUNT_PATHS.ERROR} component={AsyncMyAccountErrorPage} />
        <Route path={GLOBAL_PATHS.PENDING} component={AsyncPendingCoreUser} />




        {reactMyAccount ? (
          <AuthenticatedRoute
            exact
            path={ACCOUNT_PATHS.ACCOUNT}
            component={props => (
              <AsyncMfaWrapper {...props} path={ACCOUNT_PATHS.ACCOUNT} />
            )}
          />
        ) : (
            <AuthenticatedRoute
              path={ACCOUNT_PATHS.ACCOUNT}
              component={AsyncRetailMyAccount}
            />
          )}

        <AuthenticatedRoute
          exact
          path={GLOBAL_PATHS.MFA}
          component={AsyncMfaWrapper}
        />

        <Route
          exact
          path={GLOBAL_PATHS.MFA_FAIL}
          render={() => <AsyncVerificationFailed type="login" />}
        />

        <Route
          exact
          path={ENROLLMENT_PATHS.ENROLLMENT}
          component={(props) => {
            if (redirectToP360 && !isPocket360Domain) {
              return <PartnerSiteRedirectWrapper {...props} />
            }
            return <AsyncEnrollmentPage {...props} />
          }}
        />

        <Route path={ENROLLMENT_PATHS.ERROR} component={AsyncEnrollmentPage} />

        {Feature.enabled('LOAN_CONVERSION') && (
          <AuthenticatedRoute
            path={LOAN_PATHS.CONVERSION}
            component={LoanConversion}
          />
        )}
        <Route
          exact
          path={APPLICATION_PATHS.ADVERSE_ACTION}
          component={AsyncAdverseActionPage}
        />
        <Route
          exact
          path={APPLICATION_PATHS.ADVERSE_ACTION_THANK_YOU}
          component={AsyncAdverseActionThankYouPage}
        />
        <Route
          exact
          path={APPLICATION_PATHS.ADVERSE_ACTION_RETAIL}
          component={AsyncAdverseActionStorePage}
        />
        <Route
          exact
          path={APPLICATION_PATHS.ADVERSE_ACTION_KEEP_IN_MIND}
          component={AsyncAdverseActionKeepUsInMindPage}
        />

        {enablePasswordlessFlow &&
           <Route
            exact
            path={APPLICATION_PATHS.RESUME_PASSWORDLESS}
            component={(props) => {
              if (redirectToP360 && !isPocket360Domain) {
                return <PartnerSiteRedirectWrapper {...props} />
              }
              return <AsyncResumePasswordless />
            }}
              
              
          />
        }
       
        <Route
          exact
          path={GLOBAL_PATHS.LOGIN}
          component={(props) => {
            const { access_token, id_token } = parseLocationHash(document.location.hash)
            const { resumeApp } = parseLocationSearch(document.location.search)
            const isTryingToLogIn = (!isEmpty(access_token) && !isEmpty(id_token)) || resumeApp === "true"

            if (redirectToP360 && !isPocket360Domain && !isTryingToLogIn) {
              return <PartnerSiteRedirectWrapper {...props} />
            }
            return <LoginRoute {...props} />
          }}
        />

        <LoginRouteSSO exact admin path={ADMIN_PATHS.LOGIN} />

        <Route
          exact
          path={GLOBAL_PATHS.FORGOT_PASSWORD}
          component={(props) => {
            if (redirectToP360 && !isPocket360Domain) {
              return <PartnerSiteRedirectWrapper {...props} />
            }
            return <AsyncForgotPassword {...props} />
          }}
        />

        {forgotPasswordEnabled && (
          <Route
            exact
            path={GLOBAL_PATHS.FORGOT_PASSWORD_FAILED}
            render={() => <AsyncVerificationFailed type="forgot-password" />}
          />
        )}

        {forgotEmailEnabled && (
          <Route
            exact
            path={GLOBAL_PATHS.FORGOT_EMAIL}
            component={(props) => {
              if (redirectToP360 && !isPocket360Domain) {
                return <PartnerSiteRedirectWrapper {...props} />
              }
              return <AsyncForgotEmail {...props} />
            }}
          />
        )}

        {forgotEmailEnabled && (
          <Route
            exact
            path={GLOBAL_PATHS.FORGOT_EMAIL_FAILED}
            render={() => <AsyncVerificationFailed type="forgot-email" />}
          />
        )}

        <Route
          exact
          path={GLOBAL_PATHS.CHANGE_PASSWORD}
          component={AsyncChangePassword}
        />
        <Route
          path={GLOBAL_PATHS.APPLICATION}
          component={AsyncApplicationWrapper}
        />
        <AuthenticatedRoute
          admin
          path={ADMIN_PATHS.LANDING}
          component={AsyncAdminLandingPage}
        />
        <Route exact path="/">
          <Redirect to={Branding.current.key === ONLINE_STORE_BRAND_NAME.P360 ? GLOBAL_PATHS.LOGIN : APPLICATION_PATHS.ACCOUNT} />
        </Route>

        <Route
          render={() => {
            if (Branding.current.key === ONLINE_STORE_BRAND_NAME.P360) {
              window.location = process.env.REACT_APP_P360_NOT_FOUND
            }
            else {
              window.location = process.env.REACT_APP_PAGE_NOT_FOUND
            }
            return null
          }}
        />
      </Switch>
    </div>
  )
}

export default App
