import React from 'react'
import { Container, Button, Row, Col } from 'reactstrap'
import Branding from 'shared/branding'
import { Helmet, PartnerSiteRedirectWrapper } from 'shared/components'

class ApprovedTransition extends React.Component {
  state = {
    redirect: false,
  }

  handleSubmit = e => {
    this.setState({ redirect: true })
  }

  render() {
    const {
      name,
      partnerSiteName,
      logoImage,
      partnerSiteLogo,
      partnerSiteKey,
    } = Branding.current
    const { redirect } = this.state
    if (redirect) {
      return <PartnerSiteRedirectWrapper
        isAppRedirect={true}
        brand={partnerSiteKey}
        redirectToBrand={partnerSiteKey}
      />
    }

    return (
      <div>
        <Helmet brandName={name} pageName="Approved" />
        <Container className="my-5">
          <Col lg="8" className="mx-auto">
            <Row>
              <Col xs="6">
                <div className="mx-auto w-70">
                  <img src={logoImage} alt={name} />
                </div>
              </Col>
              <Col xs="6">
                <div className="mx-auto w-70">
                  <img src={partnerSiteLogo} alt={partnerSiteName} />
                </div>
              </Col>
            </Row>

            <hr />
            <Row>
              <Col md="12 text-center">
                <h3>Great news - Your application is approved.</h3>
                <p>
                  Just a few more steps to get your funds. To complete the
                  process and sign your loan agreement, you’re being redirected
                  to the <b>{partnerSiteName}</b> website.{' '}
                  <b>{partnerSiteName}</b> services all loan applications
                  submitted to us online. We are both part of the same family of
                  companies – so whether you’re dealing with <b>{name}</b> or{' '}
                  <b>{partnerSiteName}</b>, you can count on the same
                  outstanding service.
                  <br />{' '}
                  <b>
                    Click CONTINUE now to complete the loan process with{' '}
                    {partnerSiteName}.
                  </b>
                </p>
              </Col>
            </Row>
            <hr />
            <div className="mt-2 text-center">
              <Button
                id="approved_transition--button-continue"
                color="primary"
                onClick={this.handleSubmit}
              >
                Continue
              </Button>
            </div>
          </Col>
        </Container>
      </div>
    )
  }
}

export default ApprovedTransition
